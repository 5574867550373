precision lowp float;
varying vec2 vUv;
uniform float uWindowW;
uniform float uWindowH;
uniform vec2 uMousePos;
uniform float uGameTime;
uniform sampler2D uNoiseTex;
vec2 flipUV;
vec2 dotPos;

vec3 secondaryCol = vec3(0.1216, 0.1216, 0.1216);


//Photoshop style overlay
vec3 overlayBlend(vec3 b, vec3 bc) {
    vec3 r = mix(2.0 * b * bc, 1.0 - 2.0 * (1.0 - b) * (1.0 - bc), step(0.5, b));
    return r;
}

//rotate UV for texture
vec2 rotateUV(vec2 uv, float angle, float offset) {
    float c = cos(angle+offset), s = sin(angle+offset);
    return mat2(c, -s, s, c) * (uv - vec2(0.5)) + vec2(0.5);
}

//offset hue
//First we convert rgb to hsv. We find the maximum & min of rgb.
//c is always .rgb, the rest is .xyz otherwise this shit is mega confusing.
vec3 rgb2hsv(vec3 c) {
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0); 
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g)); //sorting, step decides the order
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));
    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}
//Then we need it back for the gl_FragColor
vec3 hsv2rgb(vec3 c) {
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.b * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.g);
}
//Now we just call it.
vec3 shiftHue(vec3 c, float s) {
    vec3 hsv = rgb2hsv(c);
    hsv.x += s; // Shift hue
    hsv.x = mod(hsv.x, 1.0); // Wrap around at 1.0
    return hsv2rgb(hsv);
}


void main() {


    //Creating the grid, and anchoring it to the  top left by flipping the UV
    flipUV = vUv;
    flipUV.y = 1.0 - vUv.y;
    float aspectRatio = uWindowW / uWindowH;

    //making a square where the mouse is
    dotPos = vec2(uMousePos.x / uWindowW, uMousePos.y / uWindowH);


    // creating the dots - these ones suck. (round => anti aliasing => buggy)
    // Calculate the number of tiles based on the aspect ratio
    float tiles = uWindowH / 10.0; // Number of tiles i want vertically
    vec2 tiledUV = -flipUV * vec2(tiles * aspectRatio, tiles); // Scale UVs to maintain square tiles
    tiledUV = fract(tiledUV); // Get the fractional part to create tiling effect

    float dist = distance(tiledUV, vec2(0.5,0.5));
    float mask = smoothstep(0.175, 0.0, dist); //if else sucks ass because no anti aliasing, smoothsteps better.


    // drawing the background image. 2 Noise texture + overlay color shifting.
    // first we get 2 colors.
    vec3 color1 = vec3(0.0, 0.102, 1.0);
    vec3 color2 = vec3(0.0, 1.0, 0.298);
    
    //moving the texture back and fourth & rotating it
    float moving = 0.2 * sin(uGameTime * 1.0);
    vec4 noiseColorA = texture2D(uNoiseTex, rotateUV(vUv*0.1, uGameTime*0.05, 0.0) + vec2(0.0, moving*0.1));
    vec4 noiseColorB = texture2D(uNoiseTex, rotateUV(vUv*0.1, uGameTime*-0.1, 0.0) + vec2((moving+0.5)*0.2, 0.0));

    noiseColorA.rgb = overlayBlend(noiseColorA.rgb, color1);
    noiseColorB.rgb = overlayBlend(noiseColorB.rgb, color2);

    //vec3 finalOverlay = overlayBlend(noiseColorA.rgb, noiseColorB.rgb);
    vec3 finalOverlay = overlayBlend(noiseColorA.rgb, noiseColorB.rgb);
    // Change hue over time
    finalOverlay = shiftHue(finalOverlay, uGameTime * 0.1);  
    //Mixing it with secondary color to make it more subtle
    finalOverlay = mix(finalOverlay, secondaryCol, 0.8);

    gl_FragColor = vec4(mix(secondaryCol, finalOverlay.rgb, mask),1.0);
    //gl_FragColor = vec4(finalOverlay.rgb,1.0);
}
