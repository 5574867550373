import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import particleVertexShader from './shaders/particles/particleV.glsl';
import particleFragmentShader from './shaders/particles/particleF.glsl';

export class sceneGalaxy {
    constructor() {
        this.scene = new THREE.Scene();
        this.scene.name = this.constructor.name;
        this.loader = new GLTFLoader();
        this.model = undefined;
        this.isLoaded = false;
        this.sliderValue = 10.0;

        const textureLoader = new THREE.TextureLoader();
        this.positionTexture = textureLoader.load('../assets/Models/Textures/Position.png');
        this.positionTexture.wrapS = THREE.RepeatWrapping;
        this.positionTexture.wrapT = THREE.RepeatWrapping;

        this.material = new THREE.ShaderMaterial({
            vertexShader: particleVertexShader,
            fragmentShader: particleFragmentShader,
            transparent: true,
            uniforms: {
                uGameTime: { value: 0.0 },
                mousePos: { value: new THREE.Vector2(0, 0) },
                uPosTex: { value: this.positionTexture},
            }
        })
        this.init();
    }

    init() {
        this.loadModels();
    }

    loadModels() {
        this.loader.load('../assets/Models/test.glb', (gltf) => {
            gltf.scene.traverse((child) => {
                if (child.isMesh) {
                    child.material = this.material
                    this.model = child;
                }
            });
            this.createInstances(this.model, this.material);
            this.isLoaded = true;
        }, undefined, function (error) {
            console.error('An error happened', error);
        });       
    }
//INSTANCIATE THOSE PROPERLY - RIGHT NOW IT'S JUST A BUNCH OF FUCKING MESHES
    createInstances(originalMesh, material) {
        const numInstances = 309 * this.sliderValue;
        const uvOffset = 1 / numInstances;
        let newMesh, newGeometry;

        for (let i = 0; i < numInstances; i++) {
            newGeometry = originalMesh.geometry.clone(); // Clone geometry

            // Modify UVs
            const uvs = newGeometry.attributes.uv;
            for (let j = 0; j < uvs.count; j++) {
                uvs.setX(j, uvOffset * i);
                uvs.setY(j, i/60);
            }
            uvs.needsUpdate = true;

            // Create a new mesh with the modified geometry
            newMesh = new THREE.Mesh(newGeometry, material);
            newMesh.position.x = 0; // Offset each instance so they don't overlap
            this.scene.add(newMesh);
        }
    }

    sliderUpdate(sliderValue){
        this.sliderValue = sliderValue;
        console.log(this.sliderValue);
    }

    update(gameTime, deltaTime) {
        if(!this.isLoaded) return;
        // Any updates needed per frame (e.g., moving objects, updating materials)
        this.model.material.uniforms.uGameTime.value = gameTime;
    }

    purge() {
        this.positionTexture.dispose();
        this.scene.traverse((child) => {
            if (child.material) {
                child.material.dispose();
            }
            if (child.geometry) {
                child.geometry.dispose();
            }
            if (child.isMesh) {
                child.geometry.dispose();
            }
            if (child.isBufferGeometry) {
                child.dispose();
            }
        })
    }
    
}
