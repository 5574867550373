import * as THREE from 'three';
import { sceneGalaxy } from './sceneGalaxy.js'; // Import the scene handling class
import { sceneBlank } from './sceneBlank.js';
import { scenePattern } from './scenePattern.js';
import { sceneDuder } from './sceneDuder.js';
import backgroundFragmentShader from './shaders/background/fragment.glsl';
import backgroundVertexShader from './shaders/background/vertex.glsl';

const textureLoader = new THREE.TextureLoader();
const canvas = document.getElementById('canvas');
const renderer = new THREE.WebGLRenderer({ canvas: canvas, alpha: true, antialias: true });
renderer.setSize(window.innerWidth, window.innerHeight);

const positionTexture = textureLoader.load('../assets/Models/Textures/Position.png');
positionTexture.wrapS = THREE.RepeatWrapping;
positionTexture.wrapT = THREE.RepeatWrapping;

// Scene setup
const scene = new THREE.Scene();

// Camera setup
const camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 0.1, 1000);
const cameraParent = new THREE.Object3D();
cameraParent.name = "cameraParent";
cameraParent.rotation.y = -0.1 * Math.PI;
cameraParent.rotation.x = -0.1 * Math.PI;
cameraParent.add(camera);
scene.add(cameraParent);

camera.position.z = 10;
// Instantiate the Scene class
let sceneManager = new sceneBlank(scene, renderer, camera);
sceneManager.init(); // Initialize scene objects

// Scene selection & deleting old scene
document.getElementById('changeSceneBlank').onclick = function(){changeScene(sceneBlank)};
document.getElementById('changeSceneGalaxy').onclick = function(){changeScene(sceneGalaxy)};
document.getElementById('changeScenePattern').onclick = function(){changeScene(scenePattern)};
document.getElementById('changeSceneDuder').onclick = function(){changeScene(sceneDuder)};
function changeScene(newScene){
    sceneManager.purge();
    scene.remove(sceneManager.scene);
    sceneManager = new newScene();
    scene.add(sceneManager.scene);
}


// Background
const noiseTexture = textureLoader.load('../assets/Models/Textures/CloudNoise.png');
noiseTexture.wrapS = THREE.RepeatWrapping;
noiseTexture.wrapT = THREE.RepeatWrapping;
const backgroundShader = new THREE.ShaderMaterial({
    vertexShader: backgroundVertexShader,
    fragmentShader: backgroundFragmentShader,
    uniforms: {
        uGameTime: { value: 0.0 },
        uWindowW: { value: 1.0 },
        uWindowH: { value: 1.0 },
        uNoiseTex: { value: noiseTexture },
        uMousePos: { value: new THREE.Vector2(0, 0) },
        uMouseEvenet: { value: false },
    }
})
const backgroundPlane = new THREE.Mesh(new THREE.PlaneGeometry(1, 1), backgroundShader);
backgroundPlane.renderOrder = -1;
backgroundPlane.position.set(0, 0, -256);
backgroundPlane.name = "backgroundPlane";
cameraParent.add(backgroundPlane);

// Mouse interaction
document.addEventListener('mousedown', onMouseDown, false);
document.addEventListener('mousemove', onMouseMove, false);
document.addEventListener('mouseup', onMouseUp, false);
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
let isDragging = false;
let previousMousePosition = { x: 0, y: 0 };
let rotationVelocity = { x: -0.01, y: -0.02 };


//pushing an event every time the slider range value is changed
document.getElementById("slider").addEventListener("input", function() {
    sceneManager.sliderUpdate(slider.value);
});


//Random Debug Cube
//const randomCube = new THREE.Mesh(new THREE.BoxGeometry(0.3, 0.3, 0.3), new THREE.MeshBasicMaterial({ color: 0xffffff }));
//randomCube.position.set(0, 0, 0);
//randomCube.name = "randomCube";
//scene.add(randomCube);
//console.log(scene);

//Calculating the date and making sure it doesn't cause floating point errors.
let gameTime = 0.0;
let previousTime = 0.0;
let deltaTime = 0.0;
let date = new Date().getTime() / 1000.0;
let startTime = 4096;
//Just so lowp floating points don't get bogged down because date() is huge.
let updateTime = Math.ceil((date - startTime) / startTime);
date -= startTime * updateTime;


//########################################## EVENT TICK ###########################################
function animate() {
    requestAnimationFrame(animate);

    deltaTime = (performance.now() - previousTime) / 1000.0;
    previousTime = performance.now();
    gameTime += deltaTime;
    
    applyInertia();

    backgroundPlane.material.uniforms.uGameTime.value = date + gameTime;
    sceneManager.update(gameTime, deltaTime); // Update the scene through the scene manager
    renderer.render(scene, camera);
}
animate();


//############################### BACKGROUND ################################
// Call this function initially and every time the window is resized
updateBackgroundPlane();
window.addEventListener('resize', () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    updateBackgroundPlane();
    renderer.setSize(window.innerWidth, window.innerHeight);
}, false);


// Function to update plane size based on camera settings
function updateBackgroundPlane() {
    const distance = camera.position.z - backgroundPlane.position.z;
    const vFov = camera.fov * Math.PI / 180; // Convert vertical fov to radians
    const planeHeight = 2 * distance * Math.tan(vFov / 2);
    const planeWidth = planeHeight * camera.aspect;
    backgroundPlane.scale.set(planeWidth, planeHeight, 1);
    backgroundPlane.material.uniforms.uWindowH.value =  window.innerHeight;
    backgroundPlane.material.uniforms.uWindowW.value =  window.innerWidth;
}

//############################### MOUSE INTERACTION ################################
function onMouseDown(event) {
    // Calculate mouse position in normalized device coordinates (-1 to +1) for both components
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    backgroundPlane.material.uniforms.uMouseEvenet.value = true;

    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(scene.children);
    if (true) { //intersects.length > 0 to make the transparent bits invisible to the mouse
        // We hit the cube, start dragging
        isDragging = true;
        previousMousePosition.x = event.clientX;
        previousMousePosition.y = event.clientY;
    }
}

function onMouseUp(event) {
    isDragging = false;
    backgroundPlane.material.uniforms.uMouseEvenet.value = true;
}

function onMouseMove(event) {
    if (isDragging) {
        const deltaX = event.clientX - previousMousePosition.x;
        const deltaY = event.clientY - previousMousePosition.y;

        rotationVelocity.y = deltaX * 0.005;
        rotationVelocity.x = deltaY * 0.005;

        cameraParent.rotation.y -= rotationVelocity.y;
        cameraParent.rotation.x -= rotationVelocity.x;

        previousMousePosition.x = event.clientX;
        previousMousePosition.y = event.clientY;

        backgroundPlane.material.uniforms.uMousePos.value.x = mouse.x;
        backgroundPlane.material.uniforms.uMousePos.value.y = mouse.y;
    }
}

//################################## CAMERA ################################
function applyInertia() {
    if (!isDragging) {
        // Apply decaying velocity
        cameraParent.rotation.x -= rotationVelocity.x;
        cameraParent.rotation.y -= rotationVelocity.y;

        rotationVelocity.x *= 0.95;
        rotationVelocity.y *= 0.95;

        // Stop the rotation when velocity is very low
        if (Math.abs(rotationVelocity.x) < 0.0001) rotationVelocity.x = 0;
        if (Math.abs(rotationVelocity.y) < 0.0001) rotationVelocity.y = 0;
    }
}

