import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

export class sceneBlank {
    constructor(scene) {
        this.scene = new THREE.Scene();
        this.loader = new GLTFLoader();
        this.model = undefined;
        this.isLoaded = false;
        this.sliderValue = 1.0;
    }

    init() {
        this.loadModels();
    }

    loadModels() {    
    }

    sliderUpdate(sliderValue){
        this.sliderValue = sliderValue;
    }
    
    update(gameTime, deltaTime) {
    }

    dispose() {
        this.scene.traverse((child) => {
            //delete child.material;
            //delete child.geometry;
        })
    }

    purge() {
        this.scene.traverse((child) => {
            if (child.material) {
                child.material.dispose();
            }
            if (child.geometry) {
                child.geometry.dispose();
            }
            if (child.isMesh) {
                child.geometry.dispose();
            }
            if (child.isBufferGeometry) {
                child.dispose();
            }
        })
    }
}
