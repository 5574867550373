mediump float;
varying vec2 vUv;
uniform sampler2D uPosTex;
uniform float uGameTime;

vec3 Axis = vec3(1.0, 0.0, 0.0);
float Angle = 90.0;

// Rotating the thing around uAxis
mat3 rotationMatrix(vec3 axis, float degAngle) {
    float angle = radians(degAngle);
    float c = cos(angle);
    float s = sin(angle);
    float oc = 1.0 - c;

    return mat3(
        oc * axis.x * axis.x + c,         oc * axis.x * axis.y - axis.z * s, oc * axis.z * axis.x + axis.y * s,
        oc * axis.x * axis.y + axis.z * s, oc * axis.y * axis.y + c,         oc * axis.y * axis.z - axis.x * s,
        oc * axis.z * axis.x - axis.y * s, oc * axis.y * axis.z + axis.x * s, oc * axis.z * axis.z + c
    );
}

void main() {
    vUv = vec2(1.0)-uv; //I have no fucking idea why, but for some dogshit reason, the uv is flipped on .glb export.

    vUv -= vec2(0.0, uGameTime*0.03); //mod(uGameTime*0.03,1.0));

    vec3 displacedPosition = position - vec3(0.5) + vec3(0.0, -1.0, 0.0);

    vec3 displacement = texture2D(uPosTex, vUv).xyz;
    displacedPosition += displacement * 3.0;

    //Rotating the entire thing by 90° on the y axis
    mat3 rotMat = rotationMatrix(Axis, Angle);
    vec3 rotatedPosition = rotMat * displacedPosition;
    rotatedPosition -= vec3(1.25,1.2,-0.25); //fixing position after rotating

    rotatedPosition *= 2.0;
    //rotatedPosition *= -vUv.x*4.0;
    //otatedPosition *= vec3(mod(vUv,1.0).x);
    //Scaling the cubes 
    vec2 modUV = (mod(vUv, vec2(1.0)) * (mod(vUv, vec2(1.0))*-1.0 + 1.0))*60.0;
    //rotatedPosition *= vec3(modUV.x);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(rotatedPosition, 1.0);
}
