import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import particleVertexShader from './shaders/particles/particleV.glsl';
import particleFragmentShader from './shaders/particles/particleF.glsl';

export class sceneDuder {
    constructor() {
        this.scene = new THREE.Scene();
        this.scene.name = this.constructor.name;
        this.loader = new GLTFLoader();
        this.model = undefined;
        this.isLoaded = false;
        this.sliderValue = 10.0;

        const textureLoader = new THREE.TextureLoader();
        this.positionTexture = textureLoader.load('../assets/Models/Textures/Position.png');
        this.positionTexture.wrapS = THREE.RepeatWrapping;
        this.positionTexture.wrapT = THREE.RepeatWrapping;
        this.material = new THREE.MeshBasicMaterial({
            map: this.positionTexture,
            transparent: true,
            side: THREE.DoubleSide
        })
        this.init();
    }

    init() {
        this.loadModels();
    }

    loadModels() {
        this.loader.load('../assets/Models/duder.glb', (gltf) => {
            gltf.scene.traverse((child) => {
                if (child.isMesh) {
                    this.model = child;
                    this.scene.add(child);
                }
            });
            this.isLoaded = true;
        }, undefined, function (error) {
            console.error('An error happened', error);
        });       
    }


    sliderUpdate(sliderValue){
        this.sliderValue = sliderValue;
        console.log(this.sliderValue);
    }

    update(gameTime, deltaTime) {
        if(!this.isLoaded) return;
        // Any updates needed per frame (e.g., moving objects, updating materials)
        //this.model.material.uniforms.uGameTime.value = gameTime;
    }

    purge() {
        this.positionTexture.dispose();
        this.scene.traverse((child) => {
            if (child.material) {
                child.material.dispose();
            }
            if (child.geometry) {
                child.geometry.dispose();
            }
            if (child.isMesh) {
                child.geometry.dispose();
            }
            if (child.isBufferGeometry) {
                child.dispose();
            }
        })
    }
    
}
